import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout";
import Mapbox from "../../mapbox";
// import { convertProvinceIdToText } from '../../mapbox/mapboxfn'
import LegendCard from "../../mapbox/LegendCard";
import Calculate from "../../util/calculate";
import Chart from "../../components/Chart";
import Sidetab from "../../components/Sidetab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TumbonAddr from "../../util/TumbonAddr";
import region from "../../util/region";
import api from "../../util/api";
import "../index.css";
import students from "/images/students.png";
import { Select } from "antd";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import axios from "axios";

const { Option } = Select;

var categories = [
  "อ.1",
  "อ.2",
  "อ.3",
  "ป.1",
  "ป.2",
  "ป.3",
  "ป.4",
  "ป.5",
  "ป.6",
  "ม.1",
  "ม.2",
  "ม.3",
  "ม.4",
  "ม.5",
  "ม.6",
  "สกร.ชั้นประถมศึกษา",
  "สกร.ชั้นมัธยมต้น",
  "สกร.ชั้นมัธยมปลาย",
  "ปวช.",
  "ปวส.",
  "อุดมศึกษา",
];

const Province = (props) => {
  const [initData, setInitData] = useState(null);
  const [open, setOpen] = useState(true);
  const [tabvalue, setTabValue] = useState(0);
  const [filter, setFilter] = useState({
    view: "province",
    year: "2567",
    region: "ทั้งหมด",
    province_name: null,
    city_name: null,
    tumbon_name: null,
  });
  const [data, setData] = useState([]);
  const [data_map, setDataMap] = useState([]);
  const [border_color] = useState(["#1b5e20", "#388e3c", "#66bb6a", "#a5d6a7", "#94a3b8"]);
  const is_Click = useRef(false);

  const [legend_data, setLegendData] = useState({
    title: "",
    subtitle: "",
    data: [],
    footer: "",
  });

  useEffect(async () => {
    let dataSummaryAllyear = await api.getInitData("province/province-student-statistics");
    setInitData(dataSummaryAllyear.data);
  }, []);

  useEffect(async () => {
    if (initData) {
      let user_data = await api.getProfileDb();
      let province = null;
      if (user_data.ok) {
        if (user_data.profile.profile.provinceId) {
          province = TumbonAddr.find((item) => item.provinceid === user_data.profile.profile.provinceId).provincename;
        }
        filter.province_name = province;
        setFilter({ ...filter });
        setData(mapData(initData, filter));
      }
    }
  }, [initData]);

  useEffect(() => {
    if (initData && filter.province_name) {
      setData(mapData(initData, filter));
      let response = onProcessData(
        {
          province: filter["province_name"],
          city: filter["city_name"],
          tumbon: filter["tumbon_name"],
          layer: filter.view,
        },
        convertMapContent(filter.view, initData)
      );
      setDataMap([{ id: "border", layer: filter.view, data: response.data }]);
      let data_legend = [
        // { name: 'จำนวนเด็กและเยาวชนวัยเรียน', value: response.data.reduce((acc, cur) => acc + cur.tps, 0), unit: 'คน' },
        { name: "เด็กและเยาวชนในระบบการศึกษา", value: response.data.reduce((acc, cur) => acc + cur.total_student, 0), unit: "คน" },
        // { name: 'เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3-17 ปี)', value: response.data.reduce((acc, cur) => acc + cur.total_oosc, 0), unit: 'คน' },
      ];
      setLegendData({
        ...legend_data,
        title: filter.province_name,
        subtitle: filter.city_name,
        data: data_legend,
      });
    }
  }, [filter, initData]);

  const mapData = (data, filter) => {
    const _filterData = (x) => {
      if (filter.view === "province") return x.province_name === filter.province_name;
      else if (filter.view === "city") return x.province_name === filter.province_name && x.city_name === filter.city_name;
      else if (filter.view === "tumbon")
        return x.province_name === filter.province_name && x.city_name === filter.city_name && x.tumbon_name === filter.tumbon_name;
      else return true;
    };
    let result = {
      child_total: 0,
      students: 0,
      oosc: 0,
      studentStatistics: [
        {
          name: "",
          color: "#fff",
          data: [
            {
              name: "2563",
              color: "#6ee7b7",
              y: 0,
            },
            {
              name: "2564",
              color: "#6ee7b7",
              y: 0,
            },
            {
              name: "2565",
              color: "#6ee7b7",
              y: 0,
            },
            {
              name: "2566",
              color: "#6ee7b7",
              y: 0,
            },
            {
              name: "2567",
              color: "#10b981",
              y: 0,
            },
          ],
        },
      ],
      studentByRegin: [
        {
          name: "เหนือ",
          color: "#2dd4bf",
          data: [0, 0, 0, 0, 0],
        },
        {
          name: "ใต้",
          color: "#38bdf8",
          data: [0, 0, 0, 0, 0],
        },
        {
          name: "กลาง",
          color: "#818cf8",
          data: [0, 0, 0, 0, 0],
        },
        {
          name: "ตะวันออกเฉียงเหนือ",
          color: "#FFADF0",
          data: [0, 0, 0, 0, 0],
        },
      ],
      studentGender: [
        {
          name: "เพศหญิง",
          color: "#FFADF0",
          data: [0, 0, 0, 0, 0],
        },
        {
          name: "เพศชาย",
          color: "#5584AC",
          data: [0, 0, 0, 0, 0],
        },
      ],
      studentLevelEduByGender: [
        {
          name: "ชาย",
          color: "#5584AC",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "หญิง",
          color: "#FFADF0",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      studentLevelEdu: [
        {
          name: "อนุบาล",
          color: "#a5f3fc",
          data: [0, 0, 0, 0, 0],
        },
        {
          name: "ประถมศึกษา",
          color: "#c7d2fe",
          data: [0, 0, 0, 0, 0],
        },
        {
          name: "มัธยมต้น",
          color: "#e9d5ff",
          data: [0, 0, 0, 0, 0],
        },
        {
          name: "มัธยมปลาย/ปวช.",
          color: "#fbcfe8",
          data: [0, 0, 0, 0, 0],
        },
        {
          name: "อุดมศึกษา/ปวส.",
          color: "#fecdd3",
          data: [0, 0, 0, 0, 0],
        },
      ],
    };
    data.filter((item) => _filterData(item)).forEach((x) => {
      const SUFFIX_YEAR_INDEX = {
        2563: 0,
        2564: 1,
        2565: 2,
        2566: 3,
        2567: 4,
      };
      Object.keys(SUFFIX_YEAR_INDEX).forEach((key_year) => {
        let _index = SUFFIX_YEAR_INDEX[key_year];
        let students = x[`studentWomen${key_year}`] + x[`studentMen${key_year}`];
        result.studentStatistics[0].data[_index].y += students;

        result.studentByRegin[0].data[_index] += x.region_thai === "เหนือ" ? students : 0;
        result.studentByRegin[1].data[_index] += x.region_thai === "ใต้" ? students : 0;
        result.studentByRegin[2].data[_index] += x.region_thai === "กลาง" ? students : 0;
        result.studentByRegin[3].data[_index] += x.region_thai === "ตะวันออกเฉียงเหนือ" ? students : 0;

        result.studentGender[0].data[_index] += x[`studentWomen${key_year}`];
        result.studentGender[1].data[_index] += x[`studentMen${key_year}`];

        result.studentLevelEdu[0].data[_index] += x[`k_student${key_year}`];
        result.studentLevelEdu[1].data[_index] += x[`p_student${key_year}`];
        result.studentLevelEdu[2].data[_index] += x[`mt_student${key_year}`];
        result.studentLevelEdu[3].data[_index] += x[`high_student${key_year}`];
        result.studentLevelEdu[4].data[_index] += x[`pvc_student${key_year}`];
      });
      let year = filter.year;
      // result.child_total += (x[`studentWomen${year}`] + x[`studentMen${year}`] + x[`oosc3_17_${year}`])
      result.child_total += x[`studentWomen${year}`] + x[`studentMen${year}`];
      result.students += x[`studentWomen${year}`] + x[`studentMen${year}`];
      result.oosc += x[`oosc3_17_${year}`];

      // Men
      result.studentLevelEduByGender[0].data[0] -= x[`k1_m${year}`] ? x[`k1_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[1] -= x[`k2_m${year}`] ? x[`k2_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[2] -= x[`k3_m${year}`] ? x[`k3_m${year}`] : 0;

      result.studentLevelEduByGender[0].data[3] -= x[`p1_m${year}`] ? x[`p1_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[4] -= x[`p2_m${year}`] ? x[`p2_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[5] -= x[`p3_m${year}`] ? x[`p3_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[6] -= x[`p4_m${year}`] ? x[`p4_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[7] -= x[`p5_m${year}`] ? x[`p5_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[8] -= x[`p6_m${year}`] ? x[`p6_m${year}`] : 0;

      result.studentLevelEduByGender[0].data[9] -= x[`m1_m${year}`] ? x[`m1_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[10] -= x[`m2_m${year}`] ? x[`m2_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[11] -= x[`m3_m${year}`] ? x[`m3_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[12] -= x[`m4_m${year}`] ? x[`m4_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[13] -= x[`m5_m${year}`] ? x[`m5_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[14] -= x[`m6_m${year}`] ? x[`m6_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[15] -= x[`ksn_p_m${year}`] ? x[`ksn_p_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[16] -= x[`ksn_m_m${year}`] ? x[`ksn_m_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[17] -= x[`ksn_h_m${year}`] ? x[`ksn_h_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[18] -= x[`pvc_m${year}`] ? x[`pvc_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[19] -= x[`university_m${year}`] ? x[`university_m${year}`] : 0;
      result.studentLevelEduByGender[0].data[20] -= x[`university_m${year}`] ? x[`university_m${year}`] : 0;

      // Wowen
      result.studentLevelEduByGender[1].data[0] += x[`k1_f${year}`] ? x[`k1_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[1] += x[`k2_f${year}`] ? x[`k2_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[2] += x[`k3_f${year}`] ? x[`k3_f${year}`] : 0;

      result.studentLevelEduByGender[1].data[3] += x[`p1_f${year}`] ? x[`p1_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[4] += x[`p2_f${year}`] ? x[`p2_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[5] += x[`p3_f${year}`] ? x[`p3_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[6] += x[`p4_f${year}`] ? x[`p4_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[7] += x[`p5_f${year}`] ? x[`p5_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[8] += x[`p6_f${year}`] ? x[`p6_f${year}`] : 0;

      result.studentLevelEduByGender[1].data[9] += x[`m1_f${year}`] ? x[`m1_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[10] += x[`m2_f${year}`] ? x[`m2_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[11] += x[`m3_f${year}`] ? x[`m3_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[12] += x[`m4_f${year}`] ? x[`m4_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[13] += x[`m5_f${year}`] ? x[`m5_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[14] += x[`m6_f${year}`] ? x[`m6_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[15] -= x[`ksn_p_f${year}`] ? x[`ksn_p_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[16] -= x[`ksn_m_f${year}`] ? x[`ksn_m_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[17] -= x[`ksn_h_f${year}`] ? x[`ksn_h_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[18] += x[`pvc_f${year}`] ? x[`pvc_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[19] += x[`university_f${year}`] ? x[`university_f${year}`] : 0;
      result.studentLevelEduByGender[1].data[20] += x[`university_f${year}`] ? x[`university_f${year}`] : 0;
    });
    return result;
  };

  const convertMapContent = (layer, data) => {
    let res = [];
    const province = [...new Set(TumbonAddr.filter((item) => item.provincename === filter.province_name).map((item) => item.provincename))].map(
      (item) => {
        return { province: item, total_student: 0, total_oosc: 0 };
      }
    );
    const city = [
      ...new Set(TumbonAddr.filter((item) => item.provincename === filter.province_name).map((item) => item.provincename + "_" + item.cityname)),
    ].map((item) => {
      return { province: item.split("_")[0], city: item.split("_")[1], total_student: 0, total_oosc: 0 };
    });
    const tumbon = [
      ...new Set(
        TumbonAddr.filter((item) => item.provincename === filter.province_name).map(
          (item) => item.provincename + "_" + item.cityname + "_" + item.tumbonname
        )
      ),
    ].map((item) => {
      return { province: item.split("_")[0], city: item.split("_")[1], tumbon: item.split("_")[2], total_student: 0, total_oosc: 0 };
    });
    data.forEach((item) => {
      let total_student = item[`studentWomen${filter.year}`] + item[`studentMen${filter.year}`];
      let total_oosc = item[`oosc3_17_${filter.year}`];
      if (layer === "country") {
        let index = province.findIndex((ele) => ele.province === item.province_name);
        if (index !== -1) {
          !province[index]["total_student"]
            ? (province[index]["total_student"] = total_student)
            : (province[index]["total_student"] += total_student);
          !province[index]["total_oosc"] ? (province[index]["total_oosc"] = total_oosc) : (province[index]["total_oosc"] += total_oosc);
        }
        res = province;
      } else if (layer === "province") {
        let index = city.findIndex((ele) => ele.province === item.province_name && ele.city === item.city_name);
        if (index !== -1) {
          !city[index]["total_student"] ? (city[index]["total_student"] = total_student) : (city[index]["total_student"] += total_student);
          !city[index]["total_oosc"] ? (city[index]["total_oosc"] = total_oosc) : (city[index]["total_oosc"] += total_oosc);
        }
        res = city;
      } else if (layer === "city") {
        let index = tumbon.findIndex(
          (ele) =>
            ele.province === item.province_name &&
            ele.city === item.city_name &&
            ele.tumbon === (item.provinceid === "10" ? "แขวง" + item.tumbon_name : item.tumbon_name)
        );
        if (index !== -1) {
          !tumbon[index]["total_student"] ? (tumbon[index]["total_student"] = total_student) : (tumbon[index]["total_student"] += total_student);
          !tumbon[index]["total_oosc"] ? (tumbon[index]["total_oosc"] = total_oosc) : (tumbon[index]["total_oosc"] += total_oosc);
        }
        res = tumbon;
      } else if (layer === "tumbon") {
        let index = tumbon.findIndex(
          (ele) =>
            ele.province === item.province_name &&
            ele.city === item.city_name &&
            ele.tumbon === (item.provinceid === "10" ? "แขวง" + item.tumbon_name : item.tumbon_name)
        );
        if (index !== -1) {
          !tumbon[index]["total_student"] ? (tumbon[index]["total_student"] = total_student) : (tumbon[index]["total"] += total_student);
          !tumbon[index]["total_oosc"] ? (tumbon[index]["total_oosc"] = total_oosc) : (tumbon[index]["total_oosc"] += total_oosc);
        }
        res = tumbon;
      }
    });
    if (filter.region !== "ทั้งหมด" && filter.view === "country") res = res.filter((item) => item.region === filter.region);
    return res;
  };

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value);
      let color = "";
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color;
          break;
        }
      }
      return color;
    };

    let result = [];
    data.forEach((element) => {
      if (filter.province && filter.province !== "ทั้งหมด" && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element);
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element);
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element);
        }
      } else {
        result.push(element);
      }
    });
    result.map((element) => {
      // if (!isFinite((element['total_student'] + element['total_oosc']) / (element['total_student'] + element['total_oosc']))) {
      //   element['percent'] = 0
      // } else {
      //   element['percent'] = (((element['total_student'] + element['total_oosc']) / result.reduce((acc, cur) => acc + (cur.total_student + cur.total_oosc), 0)) * 100)
      // }
      if (!isFinite(element["total_student"] / element["total_student"])) {
        element["percent"] = 0;
      } else {
        element["percent"] = (element["total_student"] / result.reduce((acc, cur) => acc + cur.total_student, 0)) * 100;
      }
      return element;
    });

    let data_color = [
      // { color: '#FF9A7B', value: (average(result) * 1.5), count: 0, text: `มากกว่า ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FFAE93', value: (average(result)), count: 0, text: `${average(result).toFixed(2)}% - ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FECAB5', value: (average(result) / 2), count: 0, text: `${(average(result) / 2).toFixed(2)}% - ${average(result).toFixed(2)}%` },
      // { color: '#FFEEE7', value: (0), count: 0, text: `น้อยกว่า ${(average(result) / 2).toFixed(2)}%` },

      {
        color: border_color[0],
        value: Calculate.Percentile(
          90,
          result.map((item) => item.total_student + item.total_oosc)
        ),
        count: 0,
        text: `มาก`,
      },
      {
        color: border_color[1],
        value: Calculate.Percentile(
          50,
          result.map((item) => item.total_student + item.total_oosc)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      {
        color: border_color[2],
        value: Calculate.Percentile(
          10,
          result.map((item) => item.total_student + item.total_oosc)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ];
    result.forEach((element) => {
      element["color"] = _genColorRange(data_color, element.total_student + element.total_oosc);
      // element['opacity'] = 0.5
      element["percent"] = parseFloat(element["percent"].toFixed(2));
    });
    let res = { data: result, notic: data_color };
    return res;
  };

  const onclickBorder = (layer, filterBorder) => {
    // if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true;
      let _filter = { ...filter };
      if (layer === "province" && filterBorder.province) {
        _filter["view"] = "province";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = null;
        _filter["tumbon_name"] = null;
      } else if (layer === "city" && filterBorder.city) {
        _filter["view"] = "city";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = filterBorder.city;
        _filter["tumbon_name"] = null;
      } else if (layer === "tumbon" && filterBorder.tumbon) {
        _filter["view"] = "tumbon";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = filterBorder.city;
        _filter["tumbon_name"] = filterBorder.tumbon;
      }
      setFilter(_filter);
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true;
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onBack = () => {
    let _filter = { ...filter };
    if (_filter.view === "province") return;
    if (_filter.view === "city") {
      _filter["view"] = "province";
      _filter["city_name"] = null;
      _filter["tumbon_name"] = null;
    } else if (_filter.view === "tumbon") {
      _filter["view"] = "city";
      _filter["tumbon_name"] = null;
    }
    setFilter(_filter);
  };

  let tab_content = [
    {
      title: "",
      content: (
        <div className="block w-full px-1 lg-px-16">
          <div className="flex gap-4 justify-center">
            <Select
              placeholder="ปีการศึกษา"
              className="new-design-select"
              showSearch
              value={filter.year}
              onChange={(val) => {
                setFilter({
                  ...filter,
                  year: val,
                });
              }}
              style={{ width: 120, margin: 4, fontFamily: "Kanit" }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                // ['2/2565', '1/2565', '2/2564', '1/2564', '2/2563', '1/2563', '2/2562', '1/2562', '2/2561']
                ["2567", "2566", "2565", "2564", "2563"].map((x) => {
                  return <Option value={x}>{x}</Option>;
                })
              }
            </Select>
            <Select
              placeholder="อำเภอ"
              className="new-design-select"
              showSearch
              value={filter.city_name}
              onChange={(val) => {
                if (val === "ทั้งหมด") {
                  setFilter({
                    ...filter,
                    view: "province",
                    city_name: null,
                    tumbon_name: null,
                  });
                } else {
                  setFilter({
                    ...filter,
                    view: "city",
                    city_name: val,
                    tumbon_name: null,
                  });
                }
              }}
              style={{ width: 120, margin: 4, fontFamily: "Kanit" }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value={"ทั้งหมด"}>{"ทั้งหมด"}</Option>
              {[, ...new Set(TumbonAddr.filter((item) => item.provincename === filter.province_name).map((x) => x.cityname))]
                .filter((x) => x !== "ไม่ระบุ")
                .sort()
                .map((x) => (
                  <Option value={x}>{x}</Option>
                ))}
            </Select>
            <Select
              placeholder="ตำบล"
              className="new-design-select"
              showSearch
              disabled={filter.city_name === "ทั้งหมด" ? true : false}
              value={filter.tumbon_name}
              onChange={(val) => {
                if (val === "ทั้งหมด") {
                  setFilter({
                    ...filter,
                    view: "city",
                    tumbon_name: null,
                  });
                } else {
                  setFilter({
                    ...filter,
                    view: "tumbon",
                    tumbon_name: val,
                  });
                }
              }}
              style={{ width: 120, margin: 4, fontFamily: "Kanit" }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value={"ทั้งหมด"}>{"ทั้งหมด"}</Option>
              {filter.city_name !== "ทั้งหมด" &&
                [
                  ,
                  ...new Set(
                    TumbonAddr.filter((item) => item.provincename === filter.province_name && filter.city_name === item.cityname).map(
                      (x) => x.tumbonname
                    )
                  ),
                ]
                  .filter((x) => x !== "ไม่ระบุ")
                  .sort()
                  .map((x) => <Option value={x}>{x}</Option>)}
            </Select>
          </div>
          <div className="text-center my-4">
            <h1 className="text-3xl font-bold">เด็กและเยาวชนในระบบการศึกษา</h1>
            <h2 className="text-2xl text-[#003171] font-bold">{`"${filter.province_name}"`}</h2>

            <h1 className="text-5xl text-[#1611FF] font-bold">{data.child_total ? data.child_total.toLocaleString("en-US") : 0}</h1>
          </div>
          {/* <div className='flex flex-col xl:flex-row w-full gap-4'>
          <div className="flex w-[100%] justify-between bg-white rounded-xl shadow-md py-2 px-6" >
            <img src={students} alt="Logoeef" width={'72'} height={'72'} style={{ alignSelf: 'center' }} />
            <div>
              <div className='text-lg text-black pl-4 text-center'>  เด็กและเยาวชนในระบบการศึกษาขั้นพื้นฐาน</div>
              <div className='text-4xl text-[#106C87] text-center font-bold'> {`${data.students ? data.students.toLocaleString('en-US') : 0} คน`}</div>
            </div>
          </div>
          <div className="flex w-[100%] justify-between bg-white rounded-xl shadow-md py-2 px-6" >
            <img src={students} alt="Logoeef" width={'72'} height={'72'} style={{ alignSelf: 'center' }} />
            <div>
              <div className='text-lg text-black pl-4 text-center'>  เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3-17 ปี)</div>
              <div className='text-4xl text-[#F68631] text-center font-bold'> {`${data.oosc ? data.oosc.toLocaleString('en-US') : 0} คน`}</div>
            </div>
          </div>
        </div> */}
          <div className="flex flex-col w-full justify-between bg-white rounded-xl shadow-md py-2 px-6 my-4">
            <h1 className="text-center text-2xl font-bold justify-center">สถิติจำนวนนักเรียนในประเทศไทย</h1>
            <h2 className="text-center text-xl justify-center">ปีการศึกษา 2563 - 2567</h2>
            <Chart
              options={{
                title: "",
                type: "column",
                categories: ["2563", "2564", "2565", "2566", "2567"],
                yAxis: {
                  title: {
                    text: "จำนวนนักเรียน (คน)",
                  },
                },
              }}
              containerProps={{ style: { width: "100%", height: "300px" } }}
              // style={{ width: '', height: '320px' }}
              data={data.studentStatistics}
            />
            <p className="flex text-base my-1">
              <span className="w-1 h-1 rounded-full bg-black mr-2 my-auto"></span>จำนวนนักเรียนทั้งหมดปีการศึกษา 2563 - 2567
              สำนักงานปลัดกระทรวงศึกษาธิการ
            </p>{" "}
          </div>
          <div className="flex flex-col w-full justify-between bg-white rounded-xl shadow-md py-2 px-6 my-4">
            <h1 className="text-center text-2xl font-bold justify-center">จำนวนนักเรียนแบ่งตามเพศ</h1>
            <h2 className="text-center text-xl justify-center">ปีการศึกษา 2563 - 2567</h2>
            <Chart
              containerProps={{ style: { height: "260px" } }}
              style={{ width: "100px", height: "260px" }}
              options={{
                title: "",
                type: "line",
                categories: ["2563", "2564", "2565", "2566", "2567"],
                yAxis: {
                  title: {
                    text: "จำนวนนักเรียน (คน)",
                  },
                },
              }}
              data={data.studentGender}
            />
          </div>
          <div className="flex flex-col w-full justify-between bg-white rounded-xl shadow-md py-2 px-6 my-4">
            <h1 className="text-center text-2xl font-bold justify-center">จำนวนนักเรียนแบ่งตามเพศและระดับชั้น</h1>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "bar",
                  style: {
                    fontFamily: "Kanit",
                  },
                },
                title: {
                  text: null,
                },
                accessibility: {
                  point: {
                    valueDescriptionFormat: "{index}. Age {xDescription}, {value}%.",
                  },
                },
                xAxis: [
                  {
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1,
                    },
                    accessibility: {
                      description: "Age (male)",
                    },
                  },
                  {
                    // mirror axis on right side
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1,
                    },
                    accessibility: {
                      description: "Age (female)",
                    },
                  },
                ],
                yAxis: {
                  title: {
                    text: null,
                  },
                  labels: {
                    formatter: function () {
                      let value = Math.abs(this.value);
                      if (value >= 10e5) {
                        return Highcharts.numberFormat(value / 10e5, 1) + " ล้าน";
                      } else if (value >= 10e4) {
                        return Highcharts.numberFormat(value / 10e4, 1) + " แสน";
                      } else if (value >= 10e3) {
                        return Highcharts.numberFormat(value / 10e3, 1) + " หมื่น";
                      } else if (value >= 10e2) {
                        return Highcharts.numberFormat(value / 10e2, 1) + " พัน";
                      } else {
                        return value;
                      }
                    },

                    // function() {
                    //   return Math.abs(this.value);
                    // }
                  },
                  accessibility: {
                    description: "Percentage population",
                    rangeDescription: "Range: 0 to 5%",
                  },
                },

                plotOptions: {
                  series: {
                    stacking: "normal",
                  },
                },

                tooltip: {
                  formatter: function () {
                    return (
                      "<b>" +
                      this.series.name +
                      " " +
                      this.point.category +
                      "</b><br/>" +
                      ": " +
                      Highcharts.numberFormat(Math.abs(this.point.y), 0) +
                      " คน"
                    );
                  },
                },
                series: data.studentLevelEduByGender,
              }}
              containerProps={{ style: { height: "320px" } }}
              style={{ width: "100px", height: "320px" }}
            />
          </div>
          <div className="flex flex-col w-full justify-between bg-white rounded-xl shadow-md py-2 px-6 my-4">
            <h1 className="text-center text-2xl font-bold justify-center">จำนวนนักเรียนแบ่งตามระดับชั้น</h1>
            <h2 className="text-center text-xl justify-center">ปีการศึกษา 2563 - 2567</h2>
            <Chart
              options={{
                title: "",
                type: "column",
                yAxis: {
                  title: {
                    text: "จำนวนนักเรียน (คน)",
                  },
                },
                categories: ["2563", "2564", "2565", "2566", "2567"],
              }}
              containerProps={{ style: { height: "330px" } }}
              style={{ width: "100px", height: "320px" }}
              data={data.studentLevelEdu}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Layout Role={["partner", "eef", "partner_province"]} isHideFooter>
      <div className="relative overflow-hidden">
        <div className="w-[100vw] h-[100vh]">
          {data_map.length > 0 && (
            <Mapbox
              id={"map-cct"}
              filter={filter}
              data={data_map}
              lng={99}
              lat={13.4}
              zoom={5.2}
              className="fullmap-cct-container"
              style={"border_basic"}
              openSideTab={open}
              notic_title={"จำนวนโรงเรียนในประเทศไทย"}
              onclickBorder={onclickBorder}
              onClickMarker={onClickMarker}
            />
          )}
          <div className="absolute top-[90px] left-2 z-1">
            <LegendCard {...legend_data} />
            <div className="justify-left mt-2 mx-auto">
              {filter.view !== "province" ? (
                <button
                  class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full"
                  onClick={() => onBack()}
                >
                  <ArrowBackIcon sx={{ fontSize: 18, color: "white" }} /> ย้อนกลับ
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex">
            <div className="h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3">
              <span className="font-bold center mx-1 mb-2">สัดส่วนเด็กและเยาวชน</span>
              <div className="flex">
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}></div>
                  <div class="font-bold text-left">มาก</div>
                </div>
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}></div>
                </div>
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}></div>
                </div>
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}></div>
                  <div class="font-bold text-right">น้อย</div>
                </div>
                <div className="ml-4  flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}></div>
                  <div class="font-bold text-center">ไม่มีข้อมูล</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidetab
          title={
            <>
              สถานการณ์ด้านการศึกษาและการทำงาน <br />
              ของ กสศ. ในพื้นที่ของท่าน
            </>
          }
          tab={tab_content}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          titleRef=""
          subjectTitleRef="*ประมวลผล ณ เดือนสิงหาคม 2567"
          // handleChangeTab={handleChangeTab}
        />
      </div>
    </Layout>
  );
};

export default Province;
